* {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}
.App {
  font-family: "Open Sans", sans-serif;
  background-color: #000000;
  overflow-x: hidden;
  width: "100vw";
}
